
// @ts-nocheck


export const localeCodes =  [
  "en",
  "tr"
]

export const localeLoaders = {
  "en": [{ key: "../../../../i18n/locales/en.json", load: () => import("../../../../i18n/locales/en.json" /* webpackChunkName: "locale__vercel_path0_i18n_locales_en_json" */), cache: true }],
  "tr": [{ key: "../../../../i18n/locales/tr.json", load: () => import("../../../../i18n/locales/tr.json" /* webpackChunkName: "locale__vercel_path0_i18n_locales_tr_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../../../../i18n/i18n.config.ts?hash=133101b8&config=1" /* webpackChunkName: "__i18n_i18n_config_ts_133101b8" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute"
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n/i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "language": "en-US",
      "files": [
        "/vercel/path0/i18n/locales/en.json"
      ]
    },
    {
      "code": "tr",
      "name": "Türkçe",
      "language": "tr-TR",
      "files": [
        "/vercel/path0/i18n/locales/tr.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://v2.grapelaw.com",
  "customRoutes": "config",
  "pages": {
    "index": {
      "en": "/",
      "tr": "/"
    },
    "about": {
      "en": "/about",
      "tr": "/hakkimizda"
    },
    "us-immigration/[slug]": {
      "en": "/us-immigration/[slug]",
      "tr": "/amerika-gocmenlik/[slug]"
    },
    "us-immigration/[category]/[slug]": {
      "en": "/us-immigration/[category]/[slug]",
      "tr": "/amerika-gocmenlik/[category]/[slug]"
    },
    "blog/index": {
      "en": "/blog",
      "tr": "/blog"
    },
    "blog/[blogSlug]": {
      "en": "/blog/[blogSlug]",
      "tr": "/blog/[blogSlug]"
    },
    "blog/category/[blogCategory]": {
      "en": "/blog/category/[blogCategory]",
      "tr": "/blog/kategori/[blogCategory]"
    },
    "success-story/index": {
      "en": "/success-story",
      "tr": "/basari-hikayesi"
    },
    "success-story/[slug]": {
      "en": "/success-story/[slug]",
      "tr": "/basari-hikayesi/[slug]"
    },
    "testimonial": {
      "en": "/testimonial",
      "tr": "/muvekkillerimizden"
    },
    "e-book/index": {
      "en": "/e-book",
      "tr": "/e-kitap"
    },
    "e-book/[slug]": {
      "en": "/e-book/[slug]",
      "tr": "/e-kitap/[slug]"
    },
    "video": {
      "en": "/video",
      "tr": "/video"
    },
    "webinar": {
      "en": "/webinar",
      "tr": "/webinar"
    },
    "contact": {
      "en": "/contact",
      "tr": "/bize-ulasin"
    },
    "search": {
      "en": "/search",
      "tr": "/ara"
    },
    "privacy-policy": {
      "en": "/privacy-policy",
      "tr": "/gizlilik-politikasi"
    },
    "terms-of-use": {
      "en": "/terms-of-use",
      "tr": "/kullanim-kosullari"
    },
    "consultancy": {
      "en": "/consultancy",
      "tr": "/danismanlik"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "language": "en-US",
    "files": [
      {
        "path": "/vercel/path0/i18n/locales/en.json"
      }
    ]
  },
  {
    "code": "tr",
    "name": "Türkçe",
    "language": "tr-TR",
    "files": [
      {
        "path": "/vercel/path0/i18n/locales/tr.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
