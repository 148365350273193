import * as cheerio from "cheerio";

export function parseBulletPoints(bulletPoints: string): string[] {
  // Parse the HTML string
  const $ = cheerio.load(bulletPoints);

  // Check if there are any <ul> and <li> elements
  const htmlBullets = $("ul li");

  if (htmlBullets.length > 0) {
    // If HTML bullets are present, process them
    return htmlBullets
      .map((_, element) => $(element).text().trim())
      .get()
      .filter(point => point !== "");
  }
  else {
    // If no HTML bullets, split by new lines and process
    return bulletPoints
      .split("\n")
      .map(point => point.trim())
      .filter(point => point !== "");
  }
}

export function removeHtmlTags(html: string): string {
  return html.replace(/<[^>]*>/g, "");
}

export function parseTabs(htmlContent: string): Array<{ label: string; content: string; }> {
  const $ = cheerio.load(htmlContent);

  // Determine the top-level heading tag
  const firstHeading = $("h1, h2, h3, h4, h5, h6").first();
  if (!firstHeading.length) {
    // No headings found, return default tab
    return [
      {
        label: "Content",
        content: htmlContent,
      },
    ];
  }

  const topLevelTag = firstHeading.get(0)?.tagName ?? "h1";

  const tabsArray: Array<{ label: string; content: string; }> = [];

  // Select all top-level headings
  $(topLevelTag).each((index, element) => {
    const label = $(element).text().trim() || `Tab ${index + 1}`;
    const contentElements = [];
    let sibling = $(element).next();

    while (
      sibling.length
      && (!["h1", "h2", "h3", "h4", "h5", "h6"].includes(sibling.get(0)?.tagName ?? "")
        || (sibling.get(0)?.tagName ?? "") > topLevelTag)
    ) {
      contentElements.push($.html(sibling));
      sibling = sibling.next();
    }

    const content = contentElements.join("").trim();

    tabsArray.push({ label, content });
  });

  return tabsArray;
}
