import revive_payload_client_GqDd0LGF11 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_N0BS8312as from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ptFAffznwO from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_77dCkPINK5 from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4.2_mf4qn6wwuv27pmeplnpfdwrh2q/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_97TbQsrxtG from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_j7m4Excpjj from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_6L4JFuS2mz from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wWH4Y6r8CK from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_IXYfOB4Zy5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_daKDYn6OCz from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.30.1_vue@3.5.13_typescript@5.7.3_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import siteConfig_aq3nOy7cPF from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4.2_t_56hec3ljgzsol27nj7bbbvduiq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_5bO8cMuumM from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4.2_t_56hec3ljgzsol27nj7bbbvduiq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_lAAROz8zuZ from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4.2_t_56hec3ljgzsol27nj7bbbvduiq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_NZmB6zCt7j from "/vercel/path0/node_modules/.pnpm/nuxt-seo-utils@6.0.8_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4.2_t_56hec3ljgzsol27nj7bbbvduiq/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import switch_locale_path_ssr_2kBwcyU97w from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__magicast@0.3.5_rollup@4_nebyfme3wwny6njuwiow22hq24/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_tkQVew66EH from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_@vue+compiler-dom@3.5.13_eslint@9.18.0_jiti@2.4.2__magicast@0.3.5_rollup@4_nebyfme3wwny6njuwiow22hq24/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_UVKf1DMpHt from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_typescript@5._zkex5pb2rmlp3kys7j4tjhr75u/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_0xorzNT01l from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_typescript@5._zkex5pb2rmlp3kys7j4tjhr75u/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_GAwuinMwo7 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.21.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_typescript@5._zkex5pb2rmlp3kys7j4tjhr75u/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_CS8Nw8Hh9h from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.30.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_j2YQ4AWoZF from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.7_jiti@2.4.2_ters_u67uvjuniq2436pvmqyhd5srqi/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_DRucZxGxbk from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_typescript_ch2hpkdk3oevr4xhkqqj6oyos4/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_UMqqYsy5DO from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_typescript_ch2hpkdk3oevr4xhkqqj6oyos4/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_EwPRYH74Fv from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_typescript_ch2hpkdk3oevr4xhkqqj6oyos4/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import autoAnimate_4HQGapz9xs from "/vercel/path0/plugins/autoAnimate.ts";
import externalLink_xmXClNpE4R from "/vercel/path0/plugins/externalLink.ts";
import feedbucket_client_bcif5LEtbp from "/vercel/path0/plugins/feedbucket.client.ts";
import motion_client_wgdmc4x1or from "/vercel/path0/plugins/motion.client.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
export default [
  revive_payload_client_GqDd0LGF11,
  unhead_N0BS8312as,
  router_ptFAffznwO,
  _0_siteConfig_77dCkPINK5,
  payload_client_97TbQsrxtG,
  navigation_repaint_client_j7m4Excpjj,
  check_outdated_build_client_6L4JFuS2mz,
  chunk_reload_client_wWH4Y6r8CK,
  components_plugin_zlvi6dcIsi,
  prefetch_client_IXYfOB4Zy5,
  motion_daKDYn6OCz,
  siteConfig_aq3nOy7cPF,
  inferSeoMetaPlugin_5bO8cMuumM,
  titles_lAAROz8zuZ,
  defaultsWaitI18n_NZmB6zCt7j,
  switch_locale_path_ssr_2kBwcyU97w,
  i18n_tkQVew66EH,
  slideovers_UVKf1DMpHt,
  modals_0xorzNT01l,
  colors_GAwuinMwo7,
  plugin_client_CS8Nw8Hh9h,
  plugin_j2YQ4AWoZF,
  scrollbars_client_DRucZxGxbk,
  presets_UMqqYsy5DO,
  variables_EwPRYH74Fv,
  autoAnimate_4HQGapz9xs,
  externalLink_xmXClNpE4R,
  feedbucket_client_bcif5LEtbp,
  motion_client_wgdmc4x1or,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk
]