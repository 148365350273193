<script lang="ts" setup>
import type { VisaCard } from "~/types/data";

defineProps<{
  visas: VisaCard[];
  overlay?: "green" | "blue";
  overlayHover?: boolean;
  isWide?: boolean;
}>();

const { t } = useI18n();
</script>

<template>
  <div
    class="mx-auto"
    :class="{
      'flex flex-col': isWide,
      'grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4': !isWide,
    }"
  >
    <NuxtLinkLocale
      v-for="(visa, index) in visas"
      :key="index"
      :to="{
        path: `${visa.to}`,
      }"
      class="group cursor-pointer"
    >
      <UCard
        v-if="isWide"
        class="mt-3 hover:cursor-pointer hover:shadow-xl"
        :ui="{
          body: {
            padding: 'sm:p-6',
          },
        }"
      >
        <div class="flex w-full flex-col-reverse gap-5 md:flex-row">
          <div class="flex flex-col gap-5 md:w-1/2">
            <p class="text-xl font-semibold text-text-title md:text-3xl">
              {{ visa.title }}
            </p>

            <p class="line-clamp-5 flex-grow text-base font-normal text-text-paragraph">
              {{ visa.excerpt }}
            </p>

            <p
              class="mt-auto hidden w-fit text-sm font-bold text-red-500 hover:text-red-700 md:block"
            >
              {{ t("readMore") }}
            </p>
          </div>

          <NuxtPicture
            class="md:w-1/2"
            :src="visa.image?.url || imagePlacholder.visa"
            :alt="visa.image?.alt || ''"
            :img-attrs="{
              class: 'h-full w-full rounded-lg object-cover',
            }"
            loading="lazy"
          />
        </div>
      </UCard>

      <UCard
        v-else
        class="h-full transition hover:scale-[1.01] hover:shadow-lg"
        :ui="{
          body: {
            padding: 'p-0 sm:p-0',
          },
        }"
      >
        <div class="relative">
          <NuxtPicture
            :src="visa?.image?.url || imagePlacholder.visa"
            :alt="visa.image.alt"
            loading="lazy"
            sizes="(max-width: 640px) 398px, (max-width: 768px) 326px, (max-width: 1024px) 326px, (max-width: 1280px) 326px, (max-width: 1536px) 326px, 326px"
            :class="{
              grayscale: overlay || overlayHover,
            }"
            :img-attrs="{
              class: 'w-full rounded-t-lg object-cover h-48',
            }"
          />
          <div
            class="absolute inset-0 rounded-t-lg"
            :class="{
              'block': overlay,
              'hidden bg-overlay-blue/30 group-hover:block': overlayHover,
              'bg-overlay-green/30': overlay === 'green',
              'bg-overlay-blue/30': overlay === 'blue',
            }"
          />
        </div>

        <div class="px-5 py-8">
          <h3 class="text-xl font-bold leading-tight tracking-wide">
            {{ visa.title }}
          </h3>
          <p
            v-if="visa.subtitle"
            class="text-lg font-medium leading-7"
          >
            {{ visa.subtitle }}
          </p>
          <p class="text-wrap pt-2 text-base leading-normal">
            {{ visa.excerpt }}
          </p>
        </div>
      </UCard>
    </NuxtLinkLocale>
  </div>
</template>
