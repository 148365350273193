import { ROUTE_CONFIG } from "./useRouteConfig";

export function useLocalizedRoute() {
  const defaultLocale = "en";

  const getLocalizedRoute = (locale: string, routeKey: keyof typeof ROUTE_CONFIG, params: Record<string, any> = {}) => {
    const route = ROUTE_CONFIG[routeKey];

    if (!route) {
      throw new Error(`Route key "${routeKey}" not found`);
    }

    let path = route.paths[locale as keyof typeof route.paths];
    Object.keys(params).forEach((paramKey) => {
      path = path.replace(`:${paramKey}`, params[paramKey]);
    });

    const backendSlug = "backendSlug" in route ? route.backendSlug[locale as keyof typeof route.backendSlug] : undefined;

    if (locale === defaultLocale) {
      return {
        path,
        backendSlug: backendSlug || undefined,
      };
    }

    return {
      path: `/${locale}${path}`,
      backendSlug: backendSlug || undefined,
    };
  };

  const getBackendSlugFromPath = (path: string, locale: string): string | undefined => {
    for (const routeKey in ROUTE_CONFIG) {
      const route = ROUTE_CONFIG[routeKey as keyof typeof ROUTE_CONFIG];
      const localizedPath = route.paths[locale as keyof typeof route.paths];
      if (localizedPath === path) {
        return "backendSlug" in route
          ? route.backendSlug[locale as keyof typeof route.backendSlug]
          : undefined;
      }
    }

    return undefined;
  };

  const getPathTranslation = (url: string, language: string) => {
    for (const key in ROUTE_CONFIG) {
      const paths = ROUTE_CONFIG[key as keyof typeof ROUTE_CONFIG].paths as Record<string, string>;
      for (const lang in paths) {
        if (paths[lang] === url) {
          return paths[language] || null;
        }
      }
    }
    return null;
  };

  const cleanUrl = (url: string) => {
    return url.replace(/\/tr(\/|$)/, "/");
  };

  return { getLocalizedRoute, getBackendSlugFromPath, getPathTranslation, cleanUrl };
}
