
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as _91blogSlug_93rCuzaGu5kSMeta } from "/vercel/path0/pages/blog/[blogSlug].vue?macro=true";
import { default as _91blogCategory_937MesZrV2W0Meta } from "/vercel/path0/pages/blog/category/[blogCategory].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as consultancyweFFeGSPc2Meta } from "/vercel/path0/pages/consultancy.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as privacy_45policyQl0qlywOJTMeta } from "/vercel/path0/pages/privacy-policy.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as terms_45of_45useuG8Ysh8zj7Meta } from "/vercel/path0/pages/terms-of-use.vue?macro=true";
import { default as testimonialxaM0zu0zcXMeta } from "/vercel/path0/pages/testimonial.vue?macro=true";
import { default as _91slug_93vQcVIuRYbVMeta } from "/vercel/path0/pages/us-immigration/[category]/[slug].vue?macro=true";
import { default as _91slug_93AbhSJw2etyMeta } from "/vercel/path0/pages/us-immigration/[slug].vue?macro=true";
import { default as video9ly87Y8R55Meta } from "/vercel/path0/pages/video.vue?macro=true";
import { default as webinarCViv5MmekeMeta } from "/vercel/path0/pages/webinar.vue?macro=true";
import { default as component_45stubjz3WVatLqXMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubjz3WVatLqX } from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about___en",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "about___tr",
    path: "/tr/hakkimizda",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "blog-blogSlug___en",
    path: "/blog/:blogSlug()",
    component: () => import("/vercel/path0/pages/blog/[blogSlug].vue")
  },
  {
    name: "blog-blogSlug___tr",
    path: "/tr/blog/:blogSlug()",
    component: () => import("/vercel/path0/pages/blog/[blogSlug].vue")
  },
  {
    name: "blog-category-blogCategory___en",
    path: "/blog/category/:blogCategory()",
    component: () => import("/vercel/path0/pages/blog/category/[blogCategory].vue")
  },
  {
    name: "blog-category-blogCategory___tr",
    path: "/tr/blog/kategori/:blogCategory()",
    component: () => import("/vercel/path0/pages/blog/category/[blogCategory].vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue")
  },
  {
    name: "blog___tr",
    path: "/tr/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue")
  },
  {
    name: "consultancy___en",
    path: "/consultancy",
    component: () => import("/vercel/path0/pages/consultancy.vue")
  },
  {
    name: "consultancy___tr",
    path: "/tr/danismanlik",
    component: () => import("/vercel/path0/pages/consultancy.vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___tr",
    path: "/tr/bize-ulasin",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___tr",
    path: "/tr",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/vercel/path0/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___tr",
    path: "/tr/gizlilik-politikasi",
    component: () => import("/vercel/path0/pages/privacy-policy.vue")
  },
  {
    name: "search___en",
    path: "/search",
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "search___tr",
    path: "/tr/ara",
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "terms-of-use___en",
    path: "/terms-of-use",
    component: () => import("/vercel/path0/pages/terms-of-use.vue")
  },
  {
    name: "terms-of-use___tr",
    path: "/tr/kullanim-kosullari",
    component: () => import("/vercel/path0/pages/terms-of-use.vue")
  },
  {
    name: "testimonial___en",
    path: "/testimonial",
    component: () => import("/vercel/path0/pages/testimonial.vue")
  },
  {
    name: "testimonial___tr",
    path: "/tr/muvekkillerimizden",
    component: () => import("/vercel/path0/pages/testimonial.vue")
  },
  {
    name: "us-immigration-category-slug___en",
    path: "/us-immigration/:category()/:slug()",
    component: () => import("/vercel/path0/pages/us-immigration/[category]/[slug].vue")
  },
  {
    name: "us-immigration-category-slug___tr",
    path: "/tr/amerika-gocmenlik/:category()/:slug()",
    component: () => import("/vercel/path0/pages/us-immigration/[category]/[slug].vue")
  },
  {
    name: "us-immigration-slug___en",
    path: "/us-immigration/:slug()",
    component: () => import("/vercel/path0/pages/us-immigration/[slug].vue")
  },
  {
    name: "us-immigration-slug___tr",
    path: "/tr/amerika-gocmenlik/:slug()",
    component: () => import("/vercel/path0/pages/us-immigration/[slug].vue")
  },
  {
    name: "video___en",
    path: "/video",
    component: () => import("/vercel/path0/pages/video.vue")
  },
  {
    name: "video___tr",
    path: "/tr/video",
    component: () => import("/vercel/path0/pages/video.vue")
  },
  {
    name: "webinar___en",
    path: "/webinar",
    component: () => import("/vercel/path0/pages/webinar.vue")
  },
  {
    name: "webinar___tr",
    path: "/tr/webinar",
    component: () => import("/vercel/path0/pages/webinar.vue")
  },
  {
    name: component_45stubjz3WVatLqXMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubjz3WVatLqX
  },
  {
    name: component_45stubjz3WVatLqXMeta?.name,
    path: "/tr/sitemap.xml",
    component: component_45stubjz3WVatLqX
  }
]