<script setup lang="ts">
import type { ModalState } from "./types/modal";
import { provideUseId } from "@headlessui/vue";
import { leadCollectionModalStateKey } from "./types/injectKeys";

provideUseId(() => useId());

const { siteUrl } = useRuntimeConfig().public;
const { localeProperties, locale } = useI18n();
const { getLocalizedRoute } = useLocalizedRoute();

// We need to remove the trailing / otherwise it will be duplicated in the schema.org
const sanitizedIndexPath = getLocalizedRoute(locale.value, "index").path === "/" ? "" : getLocalizedRoute(locale.value, "index").path;

useHead({
  link: [
    {
      rel: "icon",
      type: "image/png",
      href: "/favicon.ico",
    },
  ],
});

useSeoMeta({
  charset: () => "utf-8",
  viewport: () => "width=device-width, initial-scale=1.0, viewport-fit=cover",
});

useSchemaOrg([
  defineOrganization({
    "@id": `${siteUrl}${sanitizedIndexPath}/#organization`,
    "name": "Grape Law Firm PLLC",
    "alternateName": "GLF",
    "url": `${siteUrl}${sanitizedIndexPath}`,
    "description": locale.value === "tr"
      ? "Grape Law Firm PLLC, güçlü kadrosu ve uzman avukatları ile Amerika’da bireysel, ailesel ve ticari başarılarınız için birçok konuda müvekkillerine destek sunmaktadır."
      : "Grape Law Firm PLLC, with its strong team and expert attorneys, provides support to its clients in various areas for their individual, family, and commercial successes in America.",
    "address": {
      addressCountry: "US",
      addressLocality: "New York",
      addressRegion: "NY",
      postalCode: "10018",
      streetAddress: "1350 Broadway, Ste 1800",
    },
    "email": "info@grapelaw.com",
    "logo": `${siteUrl}/logo.svg`,
    "sameAs": [
      SOCIALS.facebook,
      SOCIALS.twitter,
      SOCIALS.instagram,
      SOCIALS.linkedin,
      SOCIALS.youtube,
    ],
  }),
  defineWebSite({
    "@id": `${siteUrl}${sanitizedIndexPath}/#website`,
    "url": `${siteUrl}${sanitizedIndexPath}`,
    "name": "Grape Law Firm PLLC",
    "alternateName": "GLF",
    "publisher": { "@id": `${siteUrl}/#organization` },
    "inLanguage": localeProperties.value.iso,
    "potentialAction": {
      "@type": "SearchAction",
      "target": `${siteUrl}/search?q={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
    "sameAs": locale.value === "tr"
      ? [`${siteUrl}`]
      : [`${siteUrl}/tr`],
  }),
]);

const leadModalOpen = ref(false);
function toggleModal() {
  leadModalOpen.value = !leadModalOpen.value;
}
const modalState: ModalState = { modalOpen: leadModalOpen, toggleModal };
provide(leadCollectionModalStateKey, modalState);

const languageChangePending = useState("languageChangePending", () => false);

async function handleLanguageChange() {
  languageChangePending.value = false;
}
</script>

<template>
  <div>
    <NuxtLoadingIndicator
      :duration="1000"
      color="#133949"
    />
    <LazyTheModalLeadCollection v-model="leadModalOpen" />
    <UNotifications />

    <div>
      <NuxtLayout>
        <div>
          <NuxtPage
            :transition="{
              name: !languageChangePending ? 'my' : 'none',
              mode: 'out-in',
              onAfterEnter: handleLanguageChange,
            }"
          />
        </div>
      </NuxtLayout>
    </div>

    
  </div>
</template>

<style>
.my-enter-active,
.my-leave-active {
  transition: opacity 0.15s;
}
.my-enter,
.my-leave-active {
  opacity: 0;
}
</style>
