<script setup lang="ts">
defineProps<{
  narrow?: boolean;
}>();

function loadHubspotForm() {
  if (!(window as any).hbspt) {
    const existingScript = document.querySelector(
      "script[src=\"https://js.hsforms.net/forms/embed/22781716.js\"]",
    );
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/22781716.js";
    script.defer = true;
    document.body.appendChild(script);
  }
}

onMounted(() => {
  loadHubspotForm();
});
</script>

<template>
  <div
    class="space-y-8"
    :class="{
      'mx-auto max-w-4xl': narrow,
    }"
  >
    <ClientOnly>
      <div
        class="hs-form-frame"
        data-region="na1"
        data-form-id="c463b157-e594-45c0-b3a7-71a1c61996c1"
        data-portal-id="22781716"
      />
    </ClientOnly>
  </div>
</template>
