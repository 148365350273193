<script setup lang="ts">
import type { FooterBanner } from "~/types/data";
import type { ModalState } from "~/types/modal";
import type { FooterInformationResponse } from "~/types/rest_api_types/general";
import { leadCollectionModalStateKey } from "~/types/injectKeys";

const props = defineProps<{
  pageSlug?: string;
  hideCta?: boolean;
}>();

const { $sentry } = useNuxtApp();

const { toggleModal } = inject<ModalState>(leadCollectionModalStateKey, {
  modalOpen: ref(false),
  toggleModal: () => { },
});

const { locale } = useI18n();

const defaultPageSlug = locale.value === "en" ? "other-options" : "diger-secenekler";

const { data, error } = await useAsyncData(
  "footer-banner",
  async () => {
    const pageData = await $fetch<FooterInformationResponse>(`/api/pages/footer-information?slug=${props.pageSlug ?? defaultPageSlug}`, {
      method: "GET",
    });

    const isFooterBannerComplete = pageData?.title && pageData?.image && pageData?.cta;
    if (!isFooterBannerComplete && props.pageSlug !== defaultPageSlug) {
      const generalfooterBanner = await $fetch<FooterInformationResponse>(`/api/pages/footer-information?slug=${defaultPageSlug}`, {
        method: "GET",
      });

      return {
        image: {
          url: generalfooterBanner?.image || "",
          alt: generalfooterBanner?.imageAltText || "",
        },
        title: generalfooterBanner?.title || "",
        cta: generalfooterBanner?.cta || "",
      } satisfies FooterBanner;
    }
    else {
      return {
        image: {
          url: pageData?.image || "",
          alt: pageData?.imageAltText || "",
        },
        title: pageData?.title || "",
        cta: pageData?.cta || "",
      } satisfies FooterBanner;
    }
  },
);

if (error.value) {
  $sentry.captureError(error.value);
}
</script>

<template>
  <div class="relative flex flex-col">
    <div class="relative">
      <NuxtPicture
        v-if="data?.image?.url"
        :src="data?.image?.url"
        :alt="data?.image?.alt"
        :img-attrs="{ class: 'h-72 w-full object-cover' }"
        loading="lazy"
      />

      <div
        v-if="data?.image?.url"
        class="absolute inset-0 bg-black opacity-15"
      />

      <p
        v-if="data?.image?.url"
        class="text-shadow absolute inset-x-0 bottom-16 flex items-center justify-center px-4 text-center text-2xl font-semibold tracking-tight text-white lg:text-5xl"
      >
        {{ data?.title }}
      </p>

      <div
        v-if="data?.cta && !hideCta"
        class="absolute inset-x-0 -bottom-0 z-10 flex translate-y-1/2 transform justify-center"
      >
        <UButton
          variant="solid"
          size="xl"
          color="red"
          :label="data?.cta"
          class="lead-collection-trigger px-8 font-semibold"
          @click="toggleModal"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
