<script lang="ts" setup>
import type { FormError } from "#ui/types";
import type { ModalState } from "~/types/modal";
import IconCall from "~/components/Icon/Call.vue";
import { leadCollectionModalStateKey } from "~/types/injectKeys";

const { modalOpen, toggleModal } = inject<ModalState>(leadCollectionModalStateKey, {
  modalOpen: ref(false),
  toggleModal: () => { },
});

const state = ref<"form" | "success">("form");

const { t } = useI18n();

const formData = ref({
  name: "",
  email: "",
  phone: "",
  service: "",
  message: "",
  captchaToken: "",
});

const pending = ref(false);

const formErrorToast = useToast();

async function validate(state: typeof formData.value) {
  const errors: FormError[] = [];

  if (!state.name)
    errors.push({ path: "name", message: t("formNameWarning") });
  if (!state.email)
    errors.push({ path: "email", message: t("formEmailWarning") });
  if (!state.phone)
    errors.push({ path: "phone", message: t("formPhoneWarning") });
  if (!state.captchaToken)
    errors.push({ path: "captchaToken", message: t("formCatchaTokenWarning") });
  if (state.captchaToken) {
    const response = await $fetch("/_turnstile/validate", {
      method: "POST",
      body: {
        token: state.captchaToken,
      },
    });

    if (!response.success)
      errors.push({ path: "captchaToken", message: t("formCatchaTokenWarning") });
  }

  return errors;
}

async function handleSubmit() {
  pending.value = true;

  try {
    await $fetch("/api/hubspot/form", {
      method: "post",
      body: {
        email: formData.value.email,
        name: formData.value.name,
        phone: formData.value.phone,
        service: formData.value.service,
        message: formData.value.message,
      },
    });
    state.value = "success";
  }
  catch (error) {
    console.log(error);
    formErrorToast.add({ title: t("formContactSubmitErrorTitle"), description: t("formContactSubmitErrorDescription"), color: "red" });
  }

  pending.value = false;
}
</script>

<template>
  <UModal
    v-model="modalOpen"
    :title="t('leadFormTitle')"
    :ui="{
      width: 'sm:max-w-sm',
      overlay: {
        background: 'bg-text-primary/50',
      },
    }"
    :prevent-close="!!formData.name || !!formData.email || !!formData.phone"
  >
    <UCard>
      <div v-auto-animate>
        <UForm
          v-if="state === 'form'"
          :state="formData"
          :validate="validate"
          class="space-y-4"
          @submit="handleSubmit"
        >
          <p class="text-left text-xl font-semibold text-text-title">
            {{ t('leadFormTitle') }}
          </p>

          <UFormGroup
            :label="t('formNameInput')"
            name="name"
            :ui="{
              label: {
                base: 'text-xs',
              },
            }"
          >
            <UInput
              v-model="formData.name"
              :placeholder="t('formNameInputPlaceholder')"
              :autofocus="true"
              size="xl"
            />
          </UFormGroup>

          <UFormGroup
            :label="t('formEmailInput')"
            name="email"
            :ui="{
              label: {
                base: 'text-xs',
              },
            }"
          >
            <UInput
              v-model="formData.email"
              :placeholder="t('formEmailInputPlaceholder')"
              size="xl"
              type="email"
            />
          </UFormGroup>

          <UFormGroup
            :label="t('formPhoneInput')"
            name="phone"
            :ui="{
              label: {
                base: 'text-xs',
              },
            }"
          >
            <UInput
              v-model="formData.phone"
              :placeholder="t('formPhoneInputPlaceholder')"
              size="xl"
              type="tel"
            />
          </UFormGroup>

          <UFormGroup name="captchaToken">
            <Captcha v-model="formData.captchaToken" />
          </UFormGroup>

          <UButton
            type="submit"
            :label="t('formCtaButton')"
            color="red"
            size="xl"
            class="font-semibold"
            block
            :loading="pending"
            @click.prevent="handleSubmit"
          />
        </UForm>

        <div v-else>
          <FormSuccess
            :hide-close-button="true"
            :icon="IconCall"
            :title="t('formSuccessModalTitle')"
            :description="t('formSuccessModalDescription')"
          />
        </div>
      </div>

      <UButton
        class="ring-gray-200 absolute right-4 top-4 rounded-full p-2"
        variant="outline"
        color="gray"
        @click.prevent="toggleModal"
      >
        <IconCross class="size-5" />
      </UButton>
    </UCard>
  </UModal>
</template>
