import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_eslint@9.18.0_jiti@2.4.2__ior_cb3bsqgyjcofjyjdiv2gq4mkfy/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_change-case@5.4.4_focus-trap@7.6.4_magicast@0.3.5_rollup@4.30.1_typescript_ch2hpkdk3oevr4xhkqqj6oyos4/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}