<script lang="ts" setup>
import type { ModalState } from "~/types/modal";
import type { BlogPostItem, GroupedCategory, GroupedServices, Service, VisaCategory } from "~/types/navigation";
import type { HeaderServiceCategoriesResponse } from "~/types/rest_api_types/general";
import { PopoverGroup } from "@headlessui/vue";
import IconBookmark from "~/components/Icon/Bookmark.vue";
import IconChevronDown from "~/components/Icon/ChevronDown.vue";
import IconPlayCircleOutline from "~/components/Icon/PlayCircleOutline.vue";
import IconTower from "~/components/Icon/Tower.vue";
import { leadCollectionModalStateKey } from "~/types/injectKeys";

const { $sentry } = useNuxtApp();
const { locale, setLocale, t } = useI18n();
const { getLocalizedRoute } = useLocalizedRoute();

const searchOpen = ref(false);

const languageChangePending = useState("languageChangePending", () => false);

async function changeLanguage(lang: "tr" | "en") {
  languageChangePending.value = true;
  await setLocale(lang);
}

const { toggleModal } = inject<ModalState>(leadCollectionModalStateKey, {
  modalOpen: ref(false),
  toggleModal: () => { },
});

const { data, error, status } = await useAsyncData(
  "header-links",
  async () => {
    const navReq = await $fetch<HeaderServiceCategoriesResponse>(`/api/pages/header-service-categories?language=${locale.value}`, {
      method: "GET",
    });

    if (!navReq || !navReq.services || navReq.services.length === 0) {
      return {
        visas: null,
        greenCard: null,
        resources: null,
        about: null,
      };
    }

    const groupByTopParent = (services: Service[] | null): GroupedServices => {
      const result: GroupedServices = {};

      services?.forEach((service) => {
        if (!service.visaCategories || service.visaCategories.length === 0) {
          return;
        }

        let category = service.visaCategories[0];

        if (!category) {
          return;
        }

        let topParent: VisaCategory = category;
        while (category.parent && category.parent.node) {
          category = category.parent.node;
          topParent = category;
        }

        const topParentSlug = topParent.slug?.replace(`visa-category-${locale.value}-`, "");

        if (topParentSlug && !result[topParentSlug]) {
          result[topParentSlug] = {
            icon: "",
            items: [],
          };
        }

        category = service.visaCategories[0];
        const currentGroup = result[topParentSlug ?? ""] as GroupedCategory;

        (currentGroup.items as Service[]).push({
          midLevelCategoryName: "",
          title: service.title || "Unnamed Service",
          icon: "",
          slug: service.slug || "#",
          visaCategories: [],
          topParentSlug: topParentSlug || "",
          midLevelCategorySlug: "",
          order: service.order ?? 10,
        });
      });

      return result;
    };

    const services: Service[] = navReq.services.map(service => ({
      title: service.title ?? "",
      midLevelCategoryName: service.visaCategories[0]?.name ?? "",
      slug: service.slug ?? "",
      visaCategories: service.visaCategories?.map(cat => ({
        ...cat,
        parent: cat.parent
          ? {
              node: {
                ...cat.parent,
                parent: cat.parent.parent ? { node: cat.parent.parent } : null,
              },
            }
          : null,
      })) ?? [],
      topParentSlug: "",
      midLevelCategorySlug: "",
      icon: "",
      order: service?.order ?? 10,
    }));

    const groupedServices = groupByTopParent(services);

    const blogPosts: BlogPostItem[] = (navReq.posts.map(post => ({
      title: post.title ?? "",
      slug: post.slug ?? "",
    }))) ?? [];

    const categoryMappings = {
      en: "visas",
      tr: "vizeler",
    } as { [key: string]: string; };

    const visaCategoryKey = categoryMappings[locale.value] ?? "visas";
    const greenCardSlug = "green-cards";

    return {
      usImmigrations: {
        navLabel: t("navUsImmigrationsLabel"),
        description: t("navUsImmigrationsDescription"),
        to: getLocalizedRoute(locale.value, "visas").path,
        visas: {
          navLabel: t("visaMenuVisaLabel"),
          description: t("visaMenuVisaDescription"),
          to: getLocalizedRoute(locale.value, "visas").path,
          icon: (groupedServices[visaCategoryKey] as GroupedCategory)?.icon ?? "",
          items: (groupedServices[visaCategoryKey] as GroupedCategory)?.items.map((category: Service) => {
            return {
              title: category.title,
              to: `${getLocalizedRoute(locale.value, "visas").path}/${category.slug}`,
              items: [],
            };
          }),
        },
        greenCard: {
          navLabel: t("visaMenuGreenCardLabel"),
          description: t("visaMenuGreenCardDescription"),
          to: getLocalizedRoute(locale.value, "greenCard").path,
          icon: (groupedServices[greenCardSlug] as GroupedCategory)?.icon ?? "",
          items: (groupedServices[greenCardSlug] as GroupedCategory)?.items.map((category: Service) => {
            return {
              title: category.title,
              to: `${getLocalizedRoute(locale.value, "greenCard").path}/${category.slug}`,
              items: [],
            };
          }),
        },
      },

      resources: {
        navLabel: t("navResourcesLabel"),
        description: t("navResourcesDescription"),
        subtitles: [
          {
            title: "Blog",
            to: getLocalizedRoute(locale.value, "blog").path,
            items: blogPosts.map((post: BlogPostItem) => ({
              title: post.title,
              to: `${getLocalizedRoute(locale.value, "blog").path}/${post.slug}`,
            })),
          },
          {
            title: t("navVideoLabel"),
            to: getLocalizedRoute(locale.value, "video").path,
          },
        ],
      },
      about: {
        navLabel: t("navAboutTitle"),
        to: getLocalizedRoute(locale.value, "about").path,
      },
    };
  },
  {
    watch: [locale],
  },
);

if (error.value) {
  $sentry.captureError(error.value);
  throw createError({
    statusCode: 500,
    statusMessage: t("error.anErrorOccurred"),
    fatal: true,
  });
}
</script>

<template>
  <UHeader
    :to="getLocalizedRoute(locale, 'index').path"
    :ui="{
      wrapper: 'sticky',
      panel: {
        body: 'p-0 sm:p-0',
      },
    } as any"
  >
    <template #logo>
      <LogoLabelLight class="hidden h-12 shrink-0 lg:block" />
      <Logo class="h-12 lg:hidden" />
    </template>

    <template #center>
      <Transition>
        <div
          v-if="!searchOpen"
          class="h-full"
        >
          <PopoverGroup
            as="div"
            class="hidden h-full gap-4 lg:flex xl:gap-8"
          >
            <!-- US Immigration -->
            <HeaderPopover
              v-slot="{ close }"
              :label="t('navUsImmigrationsLabel')"
              :disabled="status !== 'success'"
            >
              <div class="grid grid-cols-4 gap-0 xl:gap-8">
                <HeaderTitle class="col-span-1">
                  <div class="flex flex-col gap-2 xl:gap-2">
                    <span>{{ data?.usImmigrations?.navLabel ?? "" }}</span>
                    <NuxtLink
                      class="w-fit text-sm font-semibold leading-normal text-red-500 hover:text-red-700"
                      :to="data?.usImmigrations?.to"
                      @click="close"
                    >
                      {{ t("seeAll") }}
                    </NuxtLink>
                  </div>
                </HeaderTitle>
                <div class="col-span-3 mt-2 flex gap-3 xl:gap-6">
                  <!-- Visas -->
                  <div
                    class="flex grow basis-full flex-col gap-3"
                  >
                    <HeaderSubtitle
                      :icon="data?.usImmigrations?.visas?.icon ?? IconTower"
                      :title="data?.usImmigrations?.visas?.navLabel ?? ''"
                      :to="data?.usImmigrations?.visas?.to"
                      @click="close"
                    />
                    <div class="flex flex-wrap gap-y-2">
                      <div
                        v-for="(l, idx) in data?.usImmigrations?.visas?.items"
                        :key="idx"
                        class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3"
                      >
                        <HeaderLink
                          :label="l.title ?? ''"
                          :to="l.to"
                          @click="close"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- Green Card -->
                  <div
                    class="flex grow basis-full flex-col gap-3"
                  >
                    <HeaderSubtitle
                      :icon="data?.usImmigrations?.greenCard?.icon ?? IconTower"
                      :title="data?.usImmigrations?.greenCard?.navLabel ?? ''"
                      :to="data?.usImmigrations?.greenCard?.to"
                      @click="close"
                    />
                    <div class="flex flex-wrap gap-y-2">
                      <div
                        v-for="(l, idx) in data?.usImmigrations?.greenCard?.items"
                        :key="idx"
                        class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3"
                      >
                        <HeaderLink
                          :label="l.title ?? ''"
                          :to="l.to"
                          @click="close"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </HeaderPopover>

            <!-- Resources -->
            <HeaderPopover
              v-slot="{ close }"
              :label="data?.resources?.navLabel ?? ''"
              :disabled="status !== 'success'"
            >
              <div class="grid grid-cols-4 gap-0 xl:gap-8">
                <HeaderTitle class="col-span-1">
                  {{ data?.resources?.description ?? "" }}
                </HeaderTitle>
                <div class="col-span-3 mt-2 grid grid-cols-3 gap-3 xl:gap-6">
                  <!-- Blog -->
                  <div class="col-span-2 flex flex-col gap-3">
                    <HeaderSubtitle
                      :icon="IconBookmark"
                      :title="data?.resources?.subtitles?.[0]?.title ?? ''"
                      :to="data?.resources?.subtitles?.[0]?.to"
                      @click="close"
                    />
                    <div class="flex flex-col gap-2">
                      <HeaderLink
                        v-for="(l, idx) in data?.resources?.subtitles?.[0]?.items"
                        :key="idx"
                        :label="l.title"
                        :to="l.to"
                        @click="close"
                      />
                    </div>
                  </div>

                  <!-- Other -->
                  <div class="space-y-2">
                    <div>
                      <HeaderSubtitle
                        :icon="IconPlayCircleOutline"
                        :title="data?.resources?.subtitles?.[1]?.title ?? ''"
                        :to="data?.resources?.subtitles?.[1]?.to"
                        @click="close"
                      />
                    </div>
                    <!-- <div>
                      <HeaderSubtitle
                        :icon="IconPlaySquareOutline"
                        :title="data?.resources?.subtitles?.[2]?.title ?? ''"
                        :to="data?.resources?.subtitles?.[2]?.to"
                        @click="close"
                      />
                    </div> -->
                  </div>
                </div>
              </div>
            </HeaderPopover>

            <NuxtLinkLocale :to="data?.about?.to">
              <HeaderNavText
                :label="data?.about?.navLabel ?? '' "
                class="hover:text-blue-700"
              />
            </NuxtLinkLocale>
          </PopoverGroup>
        </div>
      </Transition>
    </template>

    <template #right>
      <div
        class="language-switcher flex items-center gap-2 lg:gap-4"
      >
        <div
          class="flex items-center gap-2"
        >
          <UDropdown
            :items="[[
              {
                label: 'Türkçe',
                class: locale === 'tr' ? 'text-blue-700 font-semibold' : '',
                click: async () => await changeLanguage('tr'),
              },
              {
                label: 'English',
                class: locale === 'en' ? 'text-blue-700 font-semibold' : '',
                click: async () => await changeLanguage('en'),
              },
            ]]"
            :ui="{
              container: 'w-fit',
              item: {
                padding: 'px-5',
              },
            }"
          >
            <UButton
              variant="ghost"
              color="gray"
              size="md"
              :label="locale.toUpperCase()"
              :ui="{
                padding: {
                  md: 'px-2',
                },
                gap: {
                  md: 'gap-x-1',
                },
              }"
            >
              <template #trailing>
                <IconChevronDown class="size-4" />
              </template>
            </UButton>
          </UDropdown>

          <HeaderSearch
            v-model="searchOpen"
            class="hidden overflow-x-hidden lg:flex"
          />
        </div>

        <UButton
          size="xl"
          color="red"
          class="lead-collection-trigger font-semibold"
          @click.prevent="toggleModal"
        >
          <span class="hidden lg:block">{{ t('contactButton') }}</span>
          <span class="block lg:hidden">{{ t('contactbuttonShorthand') }}</span>
        </UButton>
      </div>
    </template>

    <template #panel>
      <HeaderMobile
        :resources="data?.resources ?? null"
        :about="data?.about ?? null"
        :us-immigrations="[data?.usImmigrations?.visas ?? null, data?.usImmigrations?.greenCard ?? null]"
      />
    </template>
  </UHeader>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
