/**
 * **IMPORTANT: If you update the paths or slugs of this ROUTE_CONFIG, make sure to update them inside GLF Page Data plugin in the WP CMS and i18n pages config in the nuxt.config.ts file.**
 */

export const ROUTE_CONFIG = {
  visas: {
    paths: {
      en: "/us-immigration/visas",
      tr: "/amerika-gocmenlik/vizeler",
    },
    backendSlug: {
      en: "visas",
      tr: "vizeler",
    },
  },
  greenCard: {
    paths: {
      en: "/us-immigration/green-card",
      tr: "/amerika-gocmenlik/green-card",
    },
    backendSlug: {
      en: "green-cards",
      tr: "green-card",
    },
  },
  blog: {
    paths: {
      en: "/blog",
      tr: "/blog",
    },
    backendSlug: {
      en: "blog",
      tr: "blog-tr",
    },
  },
  testimonial: {
    paths: {
      en: "/testimonial",
      tr: "/muvekkillerimizden",
    },
    backendSlug: {
      en: "testimonial",
      tr: "muvekkillerimizden",
    },
  },
  webinar: {
    paths: {
      en: "/webinar",
      tr: "/webinar",
    },
    backendSlug: {
      en: "webinar",
      tr: "webinar",
    },
  },
  video: {
    paths: {
      en: "/video",
      tr: "/video",
    },
    backendSlug: {
      en: "video",
      tr: "video",
    },
  },
  contact: {
    paths: {
      en: "/contact",
      tr: "/bize-ulasin",
    },
    backendSlug: {
      en: "contact",
      tr: "bize-ulasin",
    },
  },
  about: {
    paths: {
      en: "/about",
      tr: "/hakkimizda",
    },
    backendSlug: {
      en: "about",
      tr: "hakkimizda",
    },
  },
  index: {
    paths: {
      en: "/",
      tr: "/",
    },
    backendSlug: {
      en: "home",
      tr: "anasayfa",
    },
  },
  successStory: {
    paths: {
      en: "/success-story",
      tr: "/basari-hikayesi",
    },
    backendSlug: {
      en: "success-story",
      tr: "basari-hikayesi",
    },
  },
  eBook: {
    paths: {
      en: "/e-book",
      tr: "/e-kitap",
    },
    backendSlug: {
      en: "e-book",
      tr: "e-kitap",
    },
  },
  search: {
    paths: {
      en: "/search",
      tr: "/ara",
    },
  },
  privacyPolicy: {
    paths: {
      en: "/privacy-policy",
      tr: "/gizlilik-politikasi",
    },
    backendSlug: {
      en: "privacy-policy",
      tr: "gizlilik-politikasi",
    },
  },
  consultancy: {
    paths: {
      en: "/consultancy",
      tr: "/danismanlik",
    },
  },
  termsOfUse: {
    paths: {
      en: "/terms-of-use",
      tr: "/kullanim-kosullari",
    },
    backendSlug: {
      en: "terms-of-use",
      tr: "kullanim-kosullari",
    },
  },
};
